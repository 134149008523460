
export default {
  inheritAttrs: false,
  props: {
    to: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "button",
    },
  },
};
